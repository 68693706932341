<template>
  <v-container fill-height justify-center>
    <div>
      <v-card max-width="350" class="pa-4 text-center" flat>
        <v-card-text>
          <div class="text-h6">Verify Email</div>

          <div class="mt-3">Please check your email</div>
          <div class="mb-5 text-caption">
            We have sent a code to {{ auth.email }}
          </div>
          <v-otp-input
            @finish="checkOtp()"
            v-model="otp"
            autofocus
            focusable
            focused
          ></v-otp-input>
          <div>
            Didn't get the code?
            <a @click="resendOtp">Click to resend</a>
            <v-progress-circular
              v-if="resentLoading"
              class="mx-1"
              size="10"
              width="1"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>

          <v-row class="mt-1">
            <v-col>
              <v-btn
                text
                color="secondary"
                depressed
                block
                class="text-capitalize"
                @click="goBack()"
              >
                Back
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                depressed
                block
                class="primary text-capitalize"
                @click="checkOtp()"
                :loading="loading"
              >
                Verify
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-divider class="mt-8"></v-divider> -->
          <div class="mt-5 text-caption">
            This secure portal is exclusively for authorized administrators to
            manage and oversee all aspects of <span class="primary--text">dealPartner.lk</span>.
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import { sentOTP, checkOTP } from "@/services/authService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    resentLoading: false,
    email: "",
    otp: "",
    loading: false,
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageAuthSignin" });
    },
    goHome() {
      this.$router.push({ name: "PageDashboard" });
    },
    async checkOtp() {
      this.loading = true;
      let checkOTPReturn = await checkOTP({
        email: this.auth.email,
        otp: this.otp,
      });
      this.loading = false;
      if (checkOTPReturn == "success") {
        this.goHome();
      }
    },
    async resendOtp() {
      if (this.auth.email) {
        this.resentLoading = true;
        await sentOTP({ email: this.auth.email });
        this.resentLoading = false;
      } else {
        this.goBack();
      }
    },
  },
};
</script>